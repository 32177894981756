import React, { useEffect, useState } from "react";
import axios from "axios";
import "./styles/mediaViewer.css";

const MediaViewer = () => {
    const [mediaItems, setMediaItems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const baseURL = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const fetchMediaItems = async () => {
            try {
                const response = await axios.get(`${baseURL}/projects/`);
                const results = response.data.results;

                const transformedItems = results.map(item => ({
                    type: item.video ? "video" : "image",
                    src: item.video ? item.video : item.image,
                    title: item.name,
                    description: item.description,
                }));

                setMediaItems(transformedItems);
                setLoading(false);
            } catch (err) {
                setError("Failed to fetch media items.");
                setLoading(false);
            }
        };

        fetchMediaItems();
    }, []);

    if (loading) return <div>Loading...</div>;
    if (error) return <div>{error}</div>;

    return (
        <div className="media-viewer-container">
            {mediaItems.map((item, index) => (
                <div key={index} className="media-item">
                    {item.type === "image" ? (
                        <img src={item.src} alt={item.title} className="media-image" />
                    ) : (
                        <video controls className="media-video">
                            <source src={item.src} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>
                    )}
                    <div className="media-info">
                        <h3>{item.title}</h3>
                        <p>{item.description}</p>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default MediaViewer;