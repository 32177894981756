const INFO = {
	main: {
		title: "Portfolio of Boljo",
		name: "Boljo ",
		email: "ikboljonobidjonov@gmail.com",
		logo: "../logo.png",
	},

	socials: {
		twitter: "https://twitter.com/",
		github: "https://github.com/",
		linkedin: "https://www.linkedin.com/in/ikboljon-obidjonov-5176391b9/",
		instagram: "https://instagram.com/",
		facebook: "https://facebook.com/",
	},

	homepage: {
		title: "Innovative Developer & Tech Enthusiast Crafting Efficient Solutions",
		description:
			"I am a developer and administrator with expertise in Salesforce, AI, web development, and software engineering. I enjoy solving business challenges, automating processes, and improving customer experiences. Passionate about high-quality solutions, I’m always eager to learn and grow in the tech world.",
	},

	about: {
		title: "Meet Boljo: Your Go-To Tech Wizard",
		description:
			"Hello! I'm Boljo, a passionate developer and tech enthusiast currently based in Germany. With a strong background in Salesforce, AI, web development, and software engineering, I thrive on creating innovative solutions that drive business success. I believe in the power of collaboration and am always open to connecting with like-minded professionals. Let's connect and explore how we can work together to bring cutting-edge technology to life.",
	},

	articles: {
		title: "I'm driven by innovation and dedicated to improving business solutions through Salesforce.",
		description:
			"A chronological collection of my in-depth thoughts on Salesforce development, automation, and how technology can reshape business processes.",
	},

	projects: {
		title: "Salesforce solutions I’ve built to help improve business processes.",
		description:
			"Over the years, I’ve worked on various Salesforce projects aimed at optimizing workflows and enhancing customer experiences. I'm proud of the progress I've made, and many of these solutions are impactful in streamlining business operations. If you're interested in discussing or exploring the work I've done, feel free to reach out. I believe that collaboration is a great way to grow and improve, and I’m always open to new ideas and feedback.",
	},

};

export default INFO;
