import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

import NavBar from "../components/common/navBar";
import Footer from "../components/common/footer";
import Logo from "../components/common/logo";
import Socials from "../components/about/socials";

import INFO from "../data/user";

import "./styles/contact.css";

const Contact = () => {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	return (
		<React.Fragment>
			<Helmet>
				<title>{`Contact | ${INFO.main.title}`}</title>
			</Helmet>

			<div className="page-content">
				<NavBar active="contact" />
				<div className="content-wrapper">
					<div className="contact-logo-container">
						<div className="contact-logo">
							<Logo width={46} />
						</div>
					</div>

					<div className="contact-container">
						<div className="title contact-title">
							Let's Get in Touch: Ways to Connect with Me
						</div>

						<div className="subtitle contact-subtitle">
							I appreciate your interest in connecting! Whether you’ve got a question, a fresh idea, or
							just want to chat about the exciting world of Salesforce and tech, I’m all ears.
							The fastest way to reach me is via email at&nbsp;
							<a href={`mailto:${INFO.main.email}`}>
								{INFO.main.email}
							</a>.
							I aim to reply within 24 hours, though sometimes I’m deep in code or optimizing workflows,
							so thanks for your patience!
							You can also use the contact form on my website, and I’ll circle back to you as soon as
							possible.
							If you prefer, feel free to connect on&nbsp;
							<a href={INFO.socials.linkedin} target="_blank" rel="noreferrer">
								LinkedIn
							</a> where I share updates, insights, and the occasional Salesforce tip.
							Looking forward to hearing from you and possibly collaborating on something amazing!
						</div>

					</div>

					<div className="socials-container">
						<div className="contact-socials">
							<Socials/>
						</div>
					</div>
					<br/><br/><br/><br/>

					<div className="page-footer">
						<Footer/>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Contact;
