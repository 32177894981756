import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import axios from "axios";
import { faMailBulk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
	faTwitter,
	faGithub,
	faInstagram,
} from "@fortawesome/free-brands-svg-icons";

import Logo from "../components/common/logo";
import Footer from "../components/common/footer";
import NavBar from "../components/common/navBar";

import INFO from "../data/user";

import "./styles/homepage.css";
import MediaViewer from "../components/projects/mediaViewer";

const Homepage = () => {
	const [stayLogo, setStayLogo] = useState(false);
	const [logoSize, setLogoSize] = useState(80);
	const [oldLogoSize, setOldLogoSize] = useState(80);
	const [resumeUrl, setResumeUrl] = useState("");
	const baseURL = process.env.REACT_APP_API_BASE_URL;

	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	// Fetch the resume file from API
	useEffect(() => {
		const fetchResume = async () => {
			try {
				const response = await axios.get(`${baseURL}/documents/resumes/`, {
					responseType: 'blob', // Handle binary data
				});

				const fileUrl = window.URL.createObjectURL(response.data); // Create a temporary URL
				setResumeUrl(fileUrl); // Store the URL to be used for download

				console.log("Resume URL:", fileUrl); // For debugging
			} catch (error) {
				console.error("Failed to fetch resume:", error);
			}
		};

		fetchResume();
	}, []);

	// Function to trigger file download
	const downloadCV = async () => {
		try {
			const response = await axios.get(resumeUrl, {
				responseType: 'blob', // Important to handle binary data
			});

			const blob = new Blob([response.data], { type: 'application/pdf' });
			const fileUrl = window.URL.createObjectURL(blob); // Create an object URL

			const link = document.createElement('a');
			link.href = fileUrl;
			link.setAttribute('download', 'Boljo_CV.pdf'); // Set the filename
			document.body.appendChild(link);
			link.click(); // Trigger the download
			link.remove(); // Clean up the DOM

			// Optional: Clean up the object URL
			window.URL.revokeObjectURL(fileUrl);
		} catch (error) {
			console.error("Error downloading the file:", error);
		}
	};

	useEffect(() => {
		const handleScroll = () => {
			let scroll = Math.round(window.pageYOffset, 2);

			let newLogoSize = 80 - (scroll * 4) / 10;

			if (newLogoSize < oldLogoSize) {
				if (newLogoSize > 40) {
					setLogoSize(newLogoSize);
					setOldLogoSize(newLogoSize);
					setStayLogo(false);
				} else {
					setStayLogo(true);
				}
			} else {
				setLogoSize(newLogoSize);
				setStayLogo(false);
			}
		};

		window.addEventListener("scroll", handleScroll);
		return () => window.removeEventListener("scroll", handleScroll);
	}, [logoSize, oldLogoSize]);

	const logoStyle = {
		display: "flex",
		position: stayLogo ? "fixed" : "relative",
		top: stayLogo ? "3vh" : "auto",
		zIndex: 999,
		border: stayLogo ? "1px solid white" : "none",
		borderRadius: stayLogo ? "50%" : "none",
		boxShadow: stayLogo ? "0px 4px 10px rgba(0, 0, 0, 0.25)" : "none",
	};

	return (
		<React.Fragment>
			<Helmet>
				<title>{INFO.main.title}</title>
			</Helmet>

			<div className="page-content">
				<NavBar active="home" />
				<div className="content-wrapper">
					<div className="homepage-logo-container">
						<div style={logoStyle}>
							<Logo width={logoSize} link={false} />
						</div>
					</div>

					<div className="homepage-container">
						<div className="homepage-first-area">
							<div className="homepage-first-area-left-side">
								<div className="title homepage-title">
									{INFO.homepage.title}
								</div>

								<div className="subtitle homepage-subtitle">
									{INFO.homepage.description}
								</div>
							</div>

							<div className="homepage-first-area-right-side">
								<div className="homepage-image-container">
									<div className="homepage-image-wrapper">
										<img
											src="homepage.jpg"
											alt="about"
											className="homepage-image"
										/>
									</div>
								</div>
							</div>
						</div>

						<div className="homepage-socials">
							<a
								href={INFO.socials.twitter}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faTwitter}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.github}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faGithub}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={INFO.socials.instagram}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faInstagram}
									className="homepage-social-icon"
								/>
							</a>
							<a
								href={`mailto:${INFO.main.email}`}
								target="_blank"
								rel="noreferrer"
							>
								<FontAwesomeIcon
									icon={faMailBulk}
									className="homepage-social-icon"
								/>
							</a>
							<div className="cv-download-button-container">
								<a
									onClick={() => downloadCV(resumeUrl)}  // Trigger download on click
									className="cv-download-button"
								>
									Download CV
								</a>
							</div>
						</div>

						<div className="homepage-projects">
							<h1>My top 5 projects</h1>
							<MediaViewer/>
						</div>


						<div className="page-footer">
							<Footer/>
						</div>
					</div>
				</div>
			</div>
		</React.Fragment>
	);
};

export default Homepage;
